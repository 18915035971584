import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { Icon } from "../../components/Component";
import { getCookie } from "../../utils/Utils";
import PuffLoader from "react-spinners/PuffLoader";

const FinancialOverview = () => {
  const [auOverview, setAuOverview] = useState("month-1");
  const [dashboardData, setDashboardData] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(false);
//console.log(dashboardData);

  // Fetch the dashboard data
  const fetchDashboardData = async (filterValue) => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/admin-dashboard/get-admin-dashboard-data`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authToken: getCookie("authToken"),
        },
        body: JSON.stringify({ filter_revenue_value: filterValue }),
      });
      const data = await response.json();
      setDashboardData(data.data);
       //console.log(data);
       
      // Update chart data
      const dataset = data.data.revenueGraphData;
      setChartData({
        labels: dataset.labels,
        datasets: dataset.datasets.map((ds) => ({
          ...ds,
          borderColor: "#4bc0c0",
          tension: 0.4,
        })),
      });
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch data on initial render and when auOverview changes
  useEffect(() => {
    const filterValue = auOverview === "day-7" ? "weekly" : "monthly";
    fetchDashboardData(filterValue);
  }, [auOverview]);

  if (loading) {
    return <div className="d-flex justify-content-center align-center h-100">
    <PuffLoader size={50} color={"#39ff00"} />
  </div>
  }

  if (!dashboardData) {
    return <div>No data available</div>;
  }

  return (
    <React.Fragment>
      <div className="card-title-group pb-3 g-2">
        <div className="card-title card-title-sm">
          <h6 className="title">Financial Overview</h6>
        </div>
        <div className="card-tools shrink-0 d-none d-sm-block">
          <ul className="nav nav-switch-s2 nav-tabs bg-white">
            <li className="nav-item">
              <a
                href="#navitem"
                className={auOverview === "day-7" ? "nav-link active" : "nav-link"}
                onClick={(e) => {
                  e.preventDefault();
                  setAuOverview("day-7");
                }}
              >
                7 D
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#navitem"
                className={auOverview === "month-1" ? "nav-link active" : "nav-link"}
                onClick={(e) => {
                  e.preventDefault();
                  setAuOverview("month-1");
                }}
              >
                1 M
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="analytic-ov">
        <div className="row text-center justify-around">
          <div className="nk-sale-data col-auto">
            <span className="title">Total Revenue</span>
            <div className="amount">${dashboardData.total_event_revenue || "0"}</div>
          </div>
          <div className="nk-sale-data col-auto">
            <span className="title">Upcoming Event Revenue</span>
            <div className="amount">${dashboardData.upcoming_event_revenue || "0"}</div>
          </div>
          <div className="nk-sale-data col-auto">
            <span className="title">Completed Payments</span>
            <div className="amount">${dashboardData.completed_payments || "0"}</div>
          </div>
          <div className="nk-sale-data col-auto">
            <span className="title">Pending Payments</span>
            <div className="amount">${dashboardData.pending_payments || "0"}</div>
          </div>
        </div>
        <div className="analytic-ov-ck">
          {chartData && (
            <Line
              className="analytics-line-large"
              data={chartData}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    enabled: true,
                    displayColors: false,
                    backgroundColor: "#eff6ff",
                    titleFont: {
                      size: 13,
                    },
                    titleColor: "#6783b8",
                    bodyColor: "#9eaecf",
                    bodyFont: {
                      size: 12,
                    },
                  },
                },
                maintainAspectRatio: false,
                scales: {
                  y: {
                    display: true,
                    ticks: {
                      beginAtZero: true,
                      color: "#9eaecf",
                      font: {
                        size: 12,
                      },
                      padding: 8,
                    },
                    grid: {
                      color: "rgba(82, 100, 132, 0.2)",
                    },
                  },
                  x: {
                    display: true,
                    ticks: {
                      color: "#9eaecf",
                      font: {
                        size: 12,
                      },
                    },
                    grid: {
                      color: "transparent",
                    },
                  },
                },
              }}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default FinancialOverview;
