import React, { useEffect, useState } from "react";

import { Icon, DataTableHead, DataTableRow, DataTableItem } from "../../components/Component";

const TrafficChannel = ({trafficData}) => {
  // const [dd, setdd] = useState("30");
  //const [trafficData, setTrafficData] = useState(trafficChannelData);

  // useEffect(() => {
  //   if (dd === "30") {
  //     setTrafficData(trafficChannelDataSet3);
  //   } else if (dd === "15") {
  //     setTrafficData(trafficChannelDataSet4);
  //   } else {
  //     setTrafficData(trafficChannelDataSet2);
  //   }
  // }, [dd]);

  return (
    <React.Fragment>
      <div className="card-inner mb-n2">
        <div className="card-title-group">
          <div className="card-title card-title-sm">
            <h6 className="title">Top 3 Companies with Highest Revenue</h6>
          </div>
          {/* <div className="card-tools">
            <UncontrolledDropdown>
              <DropdownToggle className="dropdown-toggle dropdown-indicator btn btn-sm btn-outline-light btn-white">
                {dd} Days
              </DropdownToggle>
              <DropdownMenu end className="dropdown-menu-xs">
                <ul className="link-list-opt no-bdr">
                  <li className={dd === "7" ? "active" : ""}>
                    <DropdownItem
                      href="#dropdownitem"
                      onClick={(e) => {
                        e.preventDefault();
                        setdd("7");
                      }}
                    >
                      <span>7 Days</span>
                    </DropdownItem>
                  </li>
                  <li className={dd === "15" ? "active" : ""}>
                    <DropdownItem
                      href="#dropdownitem"
                      onClick={(e) => {
                        e.preventDefault();
                        setdd("15");
                      }}
                    >
                      <span>15 Days</span>
                    </DropdownItem>
                  </li>
                  <li className={dd === "30" ? "active" : ""}>
                    <DropdownItem
                      href="#dropdownitem"
                      onClick={(e) => {
                        e.preventDefault();
                        setdd("30");
                      }}
                    >
                      <span>30 Days</span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div> */}
        </div>
      </div>
      <div className="nk-tb-list is-loose traffic-channel-table">
  {trafficData && trafficData.length > 0 ? (
    <>
      <DataTableHead>
        <DataTableRow className="nk-tb-channel">
          <span>Company Name</span>
        </DataTableRow>
        <DataTableRow className="nk-tb-sessions">
          <span>Total Revenue</span>
        </DataTableRow>
        <DataTableRow className="nk-tb-prev-sessions">
          <span>Completed Payments</span>
        </DataTableRow>
        <DataTableRow className="nk-tb-change">
          <span>Pending Payments</span>
        </DataTableRow>
      </DataTableHead>
      {trafficData.map((item) => (
        <DataTableItem className="nk-tb-item" key={item.user_id}>
          <DataTableRow className="nk-tb-channel">
            <span className="tb-lead">{item.company_name || "---"}</span>
          </DataTableRow>
          <DataTableRow className="nk-tb-sessions">
            <span className="tb-sub tb-amount">
              <span>{item.total_revenue || "---"}</span>
            </span>
          </DataTableRow>
          <DataTableRow className="nk-tb-prev-sessions">
            <span className="tb-sub tb-amount">
              <span>{item.completed_revenue || "---"}</span>
            </span>
          </DataTableRow>
          <DataTableRow className="nk-tb-change">
            <span className="tb-sub">
              <span>{item.cancel_revenue || "---"}</span>
            </span>
          </DataTableRow>
        </DataTableItem>
      ))}
    </>
  ) : (
    <div
      className="d-flex justify-content-center align-items-center mt-5"
    >
      <span>-No records found-</span>
    </div>
  )}
</div>

    </React.Fragment>
  );
};
export default TrafficChannel;
