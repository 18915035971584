import React, { useEffect, useState } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Row, Col, PreviewAltCard } from "../../components/Component";
import FinancialOverview1 from "./FinancialOverview1";
import EventsOverview from "./EventsOverview";
import TopCompanies from "./TopCompanies";
import MostEvents from "./MostEvents";
import MonthlyGraph from "./Monthly_Sales/MonthlyGraph";
import CompanyAnylatics from "./CompanyRegistration/CompanyAnylatics";
import { getCookie } from "../../utils/Utils";
export const NotAvailable = () => <div style={{ textAlign: "center" }}>----</div>;

const HomePage = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Fetch API data
  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/admin-dashboard/get-admin-dashboard-data`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
             authToken: getCookie("authToken"), 
          },
          body: JSON.stringify({ filter_value: "3 months" }), 
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();
       
        setDashboardData(data.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  return (
    <React.Fragment>
      <Head title="Default Dashboard" />
      <Content>
        <h3>Admin Dashboard</h3>

        {loading ? (
          <div className="fs-2 fw-bolder justify-content-center d-flex flex-column h-225px text-center">
           Loading...
        </div>
        ) : error ? (
          <p style={{ color: "red" }}>Error: {error}</p>
        ) : (
          <>
            {/* Events Overview */}
            <Row className="g-gs justify-around mb-3">
              <Col md={8}>
                <PreviewAltCard className="card-bordered h-100">
                <FinancialOverview1 />
                </PreviewAltCard>
              </Col>
              <Col md={4}>
                <PreviewAltCard className="h-100">
                  <EventsOverview 
                  totalEvents={dashboardData.total_events}
                  upcomingEvents={dashboardData.upcoming_events}
                  pastEvents={dashboardData.past_events}
                  successfullEvents={dashboardData.completed_events}
                  cancelledEvents={dashboardData.cancel_events}
                  />
                </PreviewAltCard>
              </Col>
            </Row>

            {/* Top Companies and Most Events */}
            <Row className="g-gs">
              <Col md={6}>
                <PreviewAltCard className="card-bordered h-100">
                  <TopCompanies
                     trafficData={dashboardData.top_three_companies_highest_revenue.top_three_hidhest_revenue_companies}
                  />
                </PreviewAltCard>
              </Col>
              <Col md={6}>
                <PreviewAltCard className="card-bordered h-100">
                  <MostEvents
                    trafficData={dashboardData.top_three_companies_highest_events.top_three_highest_company_events}
                  />
                </PreviewAltCard>
              </Col>
            </Row>

            <Row className={"mt-3"}>
              <Col>
                <Row className="g-gs">
                  <Col lg="6">
                    <PreviewAltCard className="card-bordered h-100">
                      <CompanyAnylatics/>
                    </PreviewAltCard>
                  </Col>
                  <Col lg="6">
                    <PreviewAltCard className="card-bordered h-100">
                      <MonthlyGraph/>
                    </PreviewAltCard>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
      </Content>
    </React.Fragment>
  );
};

export default HomePage;
