import React, { useEffect, useState } from "react";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
  Icon
} from "../../components/Component";
import Swal from "sweetalert2";
import Content from "../../layout/content/Content";
import { getCookie } from "../../utils/Utils";
import CustomizeReactDataTable from "./CustomizeReactDataTable";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import SongListModels from "./SongListModels";
export const NotAvailable = () => <div style={{ textAlign: "center" }}>----</div>;

const SongtRequest = () => {
  const [musicData, setMusicData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [pageNo, setPageNo] = useState(0); 
  const [recordLimit, setRecordLimit] = useState(10); 
  const [totalRecords, setTotalRecords] = useState(0); 
  const [, setFiles] = useState([]);

const fetchData = async () => {
  setIsLoading(true);
  try {
    const formData = new FormData();
    formData.append("page", pageNo+1);
    formData.append("limit", recordLimit);
    formData.append("search", search);
    //console.log('page No.', pageNo);

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}api/v1/admin/get-music-list`,
      {
        method: "POST",
        headers: { authToken: getCookie("authToken") },
        body: formData,
      }
    );

    const responseData = await response.json();
    //console.log(responseData);
    
    if (responseData?.data) {
      setMusicData(responseData?.data?.Musics);
      setTotalRecords(responseData?.data?.pagination?.total || 0);
    } else {
      setMusicData([]);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setIsLoading(false);
  }
};

useEffect(() => {
  fetchData();
}, [pageNo, recordLimit, search]);


  //Delete
  const deleteData = async (id) => {
    try {

      const musicExists = musicData.some(music => music.id === id);
      if (!musicExists) {
        Swal.fire("Error!", "Music not found in the list.", "error");
        return;
      }

      const result = await Swal.fire({
        title: "Are you sure to delete the music?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}api/v1/admin/delete-music`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              authToken: getCookie("authToken"),
            },
            body: JSON.stringify({ id }),
          }
        );

        const responseData = await response.json();

        if (responseData.status && responseData.message === 'Music deleted successfully') {
          setMusicData((prevData) => prevData.filter((item) => item.id !== id));
          Swal.fire("Deleted!", "The music record has been deleted permanently.", "success");
          fetchData()
        } else {
          throw new Error(`Failed to delete music. Status: ${response.status}`);
        }
      }
    } catch (error) {
      console.error("Error deleting music:", error);
      Swal.fire("Error!", error.message, "error");
    }
  };

  const dataTableColumns = [
    {
      name: "Sr.No",
      selector: (row, index) => (pageNo * recordLimit) + index + 1,
      //selector: (row) => row?.id || <NotAvailable />,
    },
    {
      name: "Music Name",
      selector: (row) => row?.title || <NotAvailable />,
    },
    {
      name: "Artist",
      selector: (row) => row?.artist || <NotAvailable />,
    },
    {
      name: "Music Link",
      selector: (row) => row?.music_provider_link || <NotAvailable />,
    },
    
    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex">
          <button
            className="btn btn-sm border-0"
            onClick={(ev) => {
              ev.preventDefault();
              onEditClick(row?.id);
              toggle("edit");
            }}
            id="edit"
          >
            <Icon name="edit" />
          </button>
          <button
            className="btn btn-sm border-0 me-2"
            onClick={(ev) => {
              ev.preventDefault();
              deleteData(row.id);
            }}
            id="deleteMusic"
          >
            <Icon name="trash" />
          </button>
        </div>
      ),
    },
  ];
 const [editId, setEditedId] = useState();

  const [view, setView] = useState({
    edit: false,
    add: false,
    details: false,
  });
  const toggle = (type) => {
    setView({
      edit: type === "edit" ? true : false,
      add: type === "add" ? true : false,
    });
  };
  const [formData, setFormData] = useState({
    title: "",
    artist: "",
    music_provider_link: "",
    id: "",
  });
 
  const resetForm = () => {
    setFormData({
        title: "",
        artist: "",
        music_provider_link: "",
        id: "",
    });
  };
  
  const onFormSubmit = async (form) => {
    setIsLoading(true);
    const {title,artist,music_provider_link,id } = form;
   
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("artist", artist);
      formData.append("music_provider_link", music_provider_link);
      formData.append("id", id);
      const musicData = await fetch(`${process.env.REACT_APP_API_URL}api/v1/admin/add-update-music`, {
        method: "POST",
        body: formData,
        headers: {
          authToken: getCookie("authToken"),
        },
      }).then((res) => res.json());
      
      
      if (musicData && musicData.status) {
        const submittedData = musicData?.data;
        toast.success(`Song Added Successfully`);
        fetchData();
        resetForm();
        setIsLoading(false);
        setView({ edit: false });
      } else {
        toast.error(musicData?.errors?.[0]);
        setIsLoading(false);
        console.log("data is invalid or missing required properties");
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(musicData?.errors?.[0]);
      console.log("Error fetching user data:", error);
    }
  }
  const onFormCancel = () => {
    setView({ edit: false, add: false, details: false });
    resetForm();
  };

  const onEditSubmit = async (form) => {
    //console.log("hello!");
    setIsLoading(true);
    const { title,artist,music_provider_link,id } = form;
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("artist", artist);
      formData.append("music_provider_link", music_provider_link);
      formData.append("id", id);
      
      const musicData= await fetch(`${process.env.REACT_APP_API_URL}api/v1/admin/add-update-music`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken"),
        },
        body: formData,
      }).then((res) => res.json());
      //console.log(companyData);
      if (musicData && musicData.status === true) {
        //console.log(companyData.status);
        const submittedData = musicData?.data;
        console.log(musicData?.data);
        toast.success(`Song Updated Successfully`);
        fetchData();
        resetForm();
        setView({ edit: false });
        setIsLoading(false);
      } else {
        console.log(musicData?.data);

        toast.error(musicData.message);
        console.log("data is invalid or missing required properties");
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(musicData.message);
      console.log("Error fetching user data:", error);

      setIsLoading(false);
    }
  };

  //console.log("companyData:",companyData);
  
  const onEditClick = (id) => {
    musicData.forEach((item) => {
      if (item.id === id) {
        setFormData({
          title: item.title,
          artist: item.artist,
          music_provider_link: item.music_provider_link,
          id: item.id,
        });
      }
    });
    setEditedId(id);
    setFiles([]);
    setView({ add: false, edit: true });
  };
  
  return (
    <>
      <Content>
        <Block size="lg">
        <BlockHead>
            <BlockHeadContent>
              <div className="d-flex flex-row align-center justify-between">
                <div>
                <BlockTitle tag="h4">Music List</BlockTitle>
                {/* <div className="d-flex flex-row">
                <span>Total Companies registration :</span>
                <span className="text-primary fw-semibold px-1">{loading ? 0 : totalRecords}</span>
              </div> */}
              </div>
                <div>
                <BlockHeadContent>
                <div className="nk-block-tools g-3">
                  <li className="nk-block-tools-opt">
                  <Button
                   className="toggle btn-icon d-md-none"
                   color="primary"
                   onClick={() => {
                   toggle("add");
                  }}
                  >
                  <Icon name="plus"></Icon>
                  </Button>
                  <Button
                   className="toggle d-none d-md-inline-flex"
                   color="primary"
                   onClick={() => {
                   toggle("add");
                   resetForm();
                  }}
                  >
                  <Icon name="plus"></Icon>
                  <span>Add Music</span>
                 </Button>
                </li>
              </div>
            </BlockHeadContent>
                </div>
              </div>
            </BlockHeadContent>
          </BlockHead>
          <PreviewCard>
            <CustomizeReactDataTable
              data={musicData || []}
              columns={dataTableColumns}
              searchBy={"name"}
              pagination
              actions
              loading={isLoading}
              pageNo={setPageNo}
              setSearch={setSearch}
              recordLimit={setRecordLimit}
              totalRecords={totalRecords}
             activePage={pageNo || 0}
            />
          </PreviewCard>
        </Block>
        <SongListModels
          view={view.add}
          modalType="add"
          formData={formData}
          setFormData={setFormData}
          onFormCancel={onFormCancel}
          onSubmit={(formData) => onFormSubmit(formData)}
          setLoading={setIsLoading}
          loading={isLoading}
          
        />
        <SongListModels
          view={view.edit}
          modalType="edit"
          formData={formData}
          setFormData={setFormData}
          onFormCancel={onFormCancel}
          onSubmit={onEditSubmit}
          setLoading={setIsLoading}
          loading={isLoading}
         
        />
      </Content>
    </>
  );
};

export default SongtRequest;
