import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { CardTitle } from "reactstrap";
import { getCookie } from "../../../utils/Utils";

const MonthlyGraph = () => {
  const [auOverview, setAuOverview] = useState("month-1");
  const [graphData, setGraphData] = useState({});
  const [totalRevenue, setTotalRevenue] = useState(0.0);

  const fetchGraphData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/admin-dashboard/get-admin-dashboard-data`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authToken: getCookie("authToken"),
        },
        body: JSON.stringify({ filter_value: "3 months" }),
      });
      const data = await response.json();
      let fetchedData = {};
      let total = 0;

      if (auOverview === "month-1") {
        fetchedData = data?.data?.subscriptionGraphData?.monthRevenue;
        total = fetchedData.datasets[0].data.reduce((acc, value) => acc + value, 0);
      } else if (auOverview === "month-3") {
        fetchedData = data?.data?.subscriptionGraphData?.quarterRevenue;
        total = fetchedData.datasets[0].data.reduce((acc, value) => acc + value, 0);
      } else {
        fetchedData = data?.data?.subscriptionGraphData?.yearRevenue;
        total = fetchedData.datasets[0].data.reduce((acc, value) => acc + value, 0);
      }

      setGraphData(fetchedData);
      setTotalRevenue(total);
    } catch (error) {
      console.error("Failed to fetch graph data:", error);
    }
  };

  useEffect(() => {
    fetchGraphData();
  }, [auOverview]);

  const isDataEmpty = graphData?.datasets?.[0]?.data?.every((value) => value === 0);

  return (
    <React.Fragment>
      <div className="card-title-group align-start mb-2">
        <CardTitle>
          <h6 className="title fs-4 fw-medium">
            {auOverview === "month-1" ? "Monthly " : auOverview === "month-3" ? "Quarterly " : "Yearly "}
            Subscriber Analytics
          </h6>
          <p className="fs-8">Monthly, Quarterly and Yearly Event Analytics</p>
        </CardTitle>
        <div className="card-tools">
          <div className="card-tools shrink-0 w-150px d-sm-block">
            <ul className="nav nav-switch-s2 nav-tabs bg-white justify-center row">
              <li className="nav-item col-12 col-sm-4 justify-end">
                <a
                  href="#navitem"
                  className={auOverview === "month-1" ? "nav-link text-primary bg-lighter" : "nav-link"}
                  onClick={(e) => {
                    e.preventDefault();
                    setAuOverview("month-1");
                  }}
                >
                  1 M
                </a>
              </li>
              <li className="nav-item col-12 col-sm-4 justify-end">
                <a
                  href="#navitem"
                  className={auOverview === "month-3" ? "nav-link text-primary bg-lighter" : "nav-link"}
                  onClick={(e) => {
                    e.preventDefault();
                    setAuOverview("month-3");
                  }}
                >
                  3 M
                </a>
              </li>
              <li className="nav-item col-12 col-sm-4 justify-end">
                <a
                  href="#navitem"
                  className={auOverview === "year-1" ? "nav-link text-primary bg-lighter" : "nav-link"}
                  onClick={(e) => {
                    e.preventDefault();
                    setAuOverview("year-1");
                  }}
                >
                  1 Y
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="align-end gy-3 gx-5 flex-wrap flex-md-nowrap flex-lg-wrap flex-xxl-nowrap">
        <div className="nk-sale-data-group flex-md-nowrap g-4">
          <div className="nk-sale-data">
            <span className="amount">
              ${totalRevenue === 0 ? "0.00" : totalRevenue.toFixed(2)}
            </span>
            <span className="sub-title fs-6">
              {auOverview === "month-1"
                ? "This Month Subscription Revenue"
                : auOverview === "month-3"
                ? "This Quarter Subscription Revenue"
                : "This Year Subscription Revenue"}
            </span>
          </div>
        </div>
      </div>
      <div className="align-end gy-3 gx-5 flex-wrap flex-md-nowrap flex-lg-wrap flex-xxl-nowrap">
        <div className="nk-sales-ck sales-revenue">
          {graphData.labels && graphData.datasets ? (
            <Bar
              className="sales-bar-chart chartjs-render-monitor"
              data={graphData}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    enabled: true,
                    displayColors: false,
                    backgroundColor: "#eff6ff",
                    titleFont: {
                      size: "11px",
                    },
                    titleColor: "#6783b8",
                    titleMarginBottom: 4,
                    bodyColor: "#9eaecf",
                    bodyFont: {
                      size: "10px",
                    },
                    bodySpacing: 3,
                    padding: 8,
                    footerMarginTop: 0,
                  },
                },
                scales: {
                  y: {
                    display: true,
                    grid: {
                      color: "#f0f0f0",
                      drawBorder: true,
                    },
                    ticks: {
                      display: false, 
                    },
                  },
                  x: {
                    display: true,
                    grid: {
                      color: "#f0f0f0",
                      drawBorder: true,
                    },
                    ticks: {
                      display: false, // Hide x-axis labels
                    },
                  },
                },
                
                maintainAspectRatio: false,
              }}
            />
          ) : (
            <p>No data available for the selected period.</p>
          )}
          {isDataEmpty && <p className="text-center text-muted">No data to display for the selected period.</p>}
        </div>
      </div>
    </React.Fragment>
  );
};

export default MonthlyGraph;
