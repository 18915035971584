import React from 'react'
import { Col, Row } from 'reactstrap'
import './EventOverview.css'
function EventsOverview({totalEvents,upcomingEvents,pastEvents,successfullEvents,cancelledEvents}) {
  return (
    <div>
               <Row>
                  <Col className="h-25 totalEvent-bg py-3 text-white d-flex justify-content-between">
                   <h5>Total Events</h5>
                   <h5>{totalEvents || "0"}</h5>
                 </Col>
               </Row>
               <Row className="mt-2">
               <Col className="h-25 upcoming-bg py-3 text-white d-flex justify-content-between">
                   <h5>Upcoming Events</h5>
                   <h5>{upcomingEvents || "0"}</h5>
                 </Col>
               </Row>
               <Row className="mt-2">
               <Col className="h-25 pastEvent-bg py-3 text-white d-flex justify-content-between">
                   <h5>Past Events</h5>
                   <h5>{pastEvents || "0"}</h5>
                 </Col>
               </Row>
               <Row className="mt-2">
               <Col className="h-25 successfullEvent-bg py-3 text-white d-flex justify-content-between">
                   <h5>Successfull Events</h5>
                   <h5>{successfullEvents || "0"}</h5>
                 </Col>
               </Row>
               <Row className="mt-2">
               <Col className="h-25 cancelledEvent-bg py-3 text-white d-flex justify-content-between">
                   <h5>Cancelled Events</h5>
                   <h5>{cancelledEvents || "0"}</h5>
                 </Col>
               </Row>
    </div>
  )
}

export default EventsOverview
